@mixin container {
  width: 100%;
  // padding-left: 16px;
  // padding-right: 16px;
  margin: 0 auto;

  //   @media screen and (min-width: 375px) {
  //     width: 100%;
  //     background-color: transparent;
  //     // width: 375px;
  //     // padding-left: 20px;
  //     // padding-right: 20px;
  //   }

  //   @media screen and (min-width: 768px) {
  //     // width: 768px;
  //     // padding-left: 32px;
  //     // padding-right: 32px;
  //   }

  //   @media screen and (min-width: 1280px) {
  //     // min-width: 1280px;
  //     // max-width: 1440px;
  //     // padding-left: 24px;
  //     // padding-right: 24px;
  //   }
  // }
}

@mixin visually-hidden {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
}

@mixin mobileSmall {
  @media screen and (max-width: 374px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (min-width: 375px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 1280px) {
    @content;
  }
}

@mixin retina {
  @media (min-device-pixel-ratio: 2),
    (min-resolution: 192dpi),
    (min-resolution: 2dppx) {
    @content;
  }
}
