@import '~modern-normalize/modern-normalize.css';
@import './vars';

html {
  box-sizing: border-box;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  margin: 0;
}

ul {
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

img {
  display: block;
}

a {
  text-decoration: none;
}
button {
  font-family: 'Poppins-Medium';
  padding: 0;
}
