@import '../../assets/styles/vars';

.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2023;

  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(255, 255, 255, 0.25);

  filter: brightness(0.2);
}

.triangles {
  // transform: translate(-50%, -50%);
  position: relative;
  height: 81px;
  width: 90px;
  // position: absolute;
  // left: 50%;
  // top: 50%;
  // z-index: 2023;

  .tri {
    position: absolute;
    animation: pulse_51 750ms ease-in infinite;
    border-top: 27px solid $black-text-color;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 0px;

    &.light {
      border-top-color: $black-text-color;
    }
    &.dark {
      border-top-color: $white-color;
    }
    &.colorful {
      border-top-color: $accent-bright-color;
    }
  }

  .tri.invert {
    border-top: 0px;
    border-bottom: 27px solid $black-text-color;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;

    &.light {
      border-bottom-color: $black-text-color;
    }
    &.dark {
      border-bottom-color: $white-color;
    }
    &.colorful {
      border-bottom-color: $accent-bright-color;
    }
  }

  .tri:nth-child(1) {
    left: 30px;
  }

  .tri:nth-child(2) {
    left: 15px;
    top: 27px;
    animation-delay: -125ms;
  }

  .tri:nth-child(3) {
    left: 30px;
    top: 27px;
  }

  .tri:nth-child(4) {
    left: 45px;
    top: 27px;
    animation-delay: -625ms;
  }

  .tri:nth-child(5) {
    top: 54px;
    animation-delay: -250ms;
  }

  .tri:nth-child(6) {
    top: 54px;
    left: 15px;
    animation-delay: -250ms;
  }

  .tri:nth-child(7) {
    top: 54px;
    left: 30px;
    animation-delay: -375ms;
  }

  .tri:nth-child(8) {
    top: 54px;
    left: 45px;
    animation-delay: -500ms;
  }

  .tri:nth-child(9) {
    top: 54px;
    left: 60px;
    animation-delay: -500ms;
  }

  @keyframes pulse_51 {
    0% {
      opacity: 1;
    }

    16.666% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}
