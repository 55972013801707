@import '../../assets/styles/vars';

.label {
  position: relative;
  display: block;

  &:not(:last-of-type) {
    margin-bottom: 14px;
  }

  &:focus-within .eye {
    opacity: 0.9;
  }
}

.avatar {
  display: block;
  width: 68px;
  height: 68px;
}

.input {
  width: 100%;
  height: 49px;
  padding: 14px 18px;

  background: $dark-color;
  color: $white-text-color;

  border: 1px solid $accent-color;
  border-radius: 8px;

  outline: none;
  opacity: 0.4;
  box-shadow: 0px 4px 16px rgba(22, 22, 22, 0.08);

  cursor: pointer;

  &:placeholder-shown {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: -0.02em;
  }

  &:focus {
    opacity: 1;
  }

  &.colorful,
  &.light {
    background: transparent;
    color: $black-text-color;
  }

  &.colorful {
    border: 1px solid $accent-bright-color;
  }
}

.wrapImage {
  position: relative;
  display: block;
  width: 68px;
  height: 68px;

  margin: 0 auto 25px;
  cursor: pointer;
}

.isHidden {
  position: absolute;
  width: 0;
  height: 0;
  left: 0;
  top: 0;
  opacity: 0;
}

.buttonPlus {
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;

  border-radius: 8px;
  background-color: $accent-color;

  color: $black-text-color;

  &.colorful {
    background-color: $light-violet;
  }
}

.image {
  display: block;
  width: 68px;
  height: 68px;
  border-radius: 8px;
}

.eye {
  position: absolute;
  width: 19px;
  height: 19px;

  stroke: currentColor;
  fill: currentColor;
  color: $white-text-color;

  right: 18px;
  bottom: 15px;

  opacity: 0.4;
  cursor: pointer;

  &.light,
  &.colorful {
    color: $black-text-color;
  }
}
