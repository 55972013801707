@import '../../assets/styles/vars';

.container {
  margin-bottom: 24px;
}

.iconTitle {
  margin-bottom: 14px;

  font-family: 'Poppins-Medium';
  font-weight: 500;
  font-size: 14px;
  line-height: calc(21 / 14);

  letter-spacing: -0.02em;
  &.dark {
    color: $white-text-color;
  }
  &.light,
  &.colorful {
    color: $black-text-color;
  }
}

.svg {
  margin-right: 8px;

  cursor: pointer;

  &.dark {
    fill: #151515;
    stroke: rgba(255, 255, 255, 0.5);
  }

  &.colorful,
  &.light {
    fill: white;
    stroke: $icon-main-color;
  }
}

.inputIcon {
  cursor: pointer;
  position: absolute;
  opacity: 0;

  & + .svg {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    cursor: pointer;
  }

  &.dark {
    &:hover + svg,
    &:checked + svg,
    &:focus + svg {
      stroke: rgba(255, 255, 255, 1);
    }
  }

  &.colorful,
  &.light {
    &:hover + svg,
    &:checked + svg,
    &:focus + svg {
      stroke: rgba(22, 22, 22, 1);
    }
  }
}
