@import '../../assets/styles/vars';
@import '../../assets/styles/mixins';

.Button {
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 335px;
  height: 56px;
  border-radius: 8px;
  cursor: pointer;
  &.Column {
    &.dark {
      background: $black-color;
    }
    &.light {
      background: $white-color;
    }
    &.colorful {
      background: $white-color;
    }
  }
  &.Card {
    width: 335px;
    &.dark {
      background-color: $accent-color;
    }
    &.light {
      background-color: $accent-color;
    }
    &.colorful {
      background: $accent-bright-color;
    }
  }
}
.TitleCard {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.02em;
  &.Column {
    &.dark {
      color: $white-color;
    }
    &.light {
      color: $black-color;
    }
    &.colorful {
      color: $black-color;
    }
  }
  &.Card {
    &.dark {
      color: $black-color;
    }
    &.light {
      color: $black-color;
    }
    &.colorful {
      color: $white-color;
    }
  }
}
.BoxPlus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;

  margin-right: 8px;
  border-radius: 6px;
  &.Column {
    &.dark {
      background-color: $white-color;
      stroke: $black-color;
    }
    &.light {
      background-color: $black-color;
      stroke: $white-color;
    }
    &.colorful {
      background-color: $accent-bright-color;
      stroke: $white-color;
    }
  }
  &.Card {
    &.dark {
      background-color: $black-color;
      stroke: $white-color;
    }
    &.light {
      background-color: $black-color;
      stroke: $white-color;
    }
    &.colorful {
      background-color: $white-color;
      stroke: $black-color;
    }
  }
}
.Svg {
  stroke: inherit;
}

@include tablet {
  .Button {
    margin-bottom: 52px;
  }
}
@include desktop {
  .Button {
    margin-bottom: 16px;
  }
}
