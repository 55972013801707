@import '../../assets/styles/vars';

.button {
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  &:hover {
    transform: scale(1.1);
  }
}

.icon {
  width: 16px;
  height: 16px;
  stroke: $icon-main-color;

  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  &:hover {
    stroke: $dark-color;
  }

  &.dark {
    stroke: $icon-secondary-color;
    &:hover {
      stroke: $white-color;
    }
  }
}
