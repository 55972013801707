@import '../../assets/styles/vars';
@import '../../assets/styles/mixins';

.wrap {
  display: flex;
  flex-direction: column;

  background: #151515;
  border-radius: 8px;
  padding: 24px;
  margin: 0 auto;

  @include mobile {
    width: 335px;
  }

  @include tablet {
    padding: 40px;
    width: 424px;
  }
}

.list {
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
  margin-bottom: 40px;

  font-weight: 500;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: -0.02em;
}

.link {
  color: rgba(255, 255, 255, 0.3);
}

.wrapForm label {
  position: relative;
  display: block;

  &:not(:last-of-type) {
    margin-bottom: 14px;
  }
}

.wrapForm input {
  width: 100%;
  height: 49px;
  padding: 14px 18px;

  background: $dark-color;
  color: $white-text-color;

  border: 1px solid $accent-color;
  border-radius: 8px;

  outline: none;
  opacity: 0.4;
  box-shadow: 0px 4px 16px rgba(22, 22, 22, 0.08);

  cursor: pointer;

  &:placeholder-shown {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: -0.02em;
  }

  &:focus {
    opacity: 1;
  }
}

.active {
  color: $white-text-color;
}
