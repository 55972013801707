@import '../../assets/styles/vars';

.headerWrapper {
  width: 201px;
  display: flex;
  margin-bottom: 28px;
  justify-content: space-between;
  font-family: 'Poppins-Medium';
  font-size: 16px;
  color: $dark-color;
  padding-bottom: 0;

  & + div {
    width: 201px;
    & > * {
      font-family: 'Poppins-Regular';
      font-weight: 500;
      font-size: 14px;
      line-height: calc(21 / 14);
      max-width: 23px;
      max-height: 23px;
      color: rgba(22, 22, 22, 0.5);
      margin-bottom: 11px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    top: 34px;
    background-color: rgba(22, 22, 22, 0.2);
    border-radius: 2px;
  }

  & button {
    width: 18px;
    height: 18px;
    color: rgba(82, 85, 188, 1);
    cursor: pointer;
  }
}

.calendarConatiner {
  width: 201px;
  height: 222px;
  overflow: hidden;

  padding-top: 0;

  border: 1px solid $accent-color;
  border-radius: 8px;
  background-color: $dark-color;

  & > :first-child() {
    display: none;
  }
}

.popperCustomClass {
  width: 233px;
  height: 254px;
  padding: 18px;

  overflow: hidden;

  border: 1px solid rgba(82, 85, 188, 1);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);

  & > div * {
    border: none;
    background-color: rgba(255, 255, 255, 1);
    padding: 0px;

    & div > div + div > div > * {
      width: 23px;
      height: 23px;
      color: $dark-color;

      font-family: 'Poppins-Medium';
      font-weight: 400;
      font-size: 14px;
      line-height: calc(18 / 14);
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: rgba(82, 85, 188, 1);
        border-radius: 50%;
        color: $white-text-color;
      }

      &[aria-disabled='true'] {
        color: rgba(22, 22, 22, 0.2);
        &:hover {
          background-color: inherit;
        }
      }

      &[aria-selected='true'] {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(82, 85, 188, 1);
        border-radius: 50%;
        color: $white-text-color;
      }
    }

    &:nth-child(2) {
      // // це дів 1-31
      margin: 0px;
    }
  }

  & > div + div {
    border: none;
    background-color: $dark-color;
  }
}

:global .react-datepicker-popper {
  z-index: 2;
  // padding-top: 18px;
}

:global .react-datepicker__triangle {
  display: none;
}

:global .react-datepicker-popper[data-placement^='bottom'] {
  padding-top: 18px;
}
