@import '../../assets/styles/vars';
@import '../../assets/styles/mixins';

.textAreaStyle {
  display: block;
  margin: 0;
  max-width: 287px;
  width: 100%;
  height: 154px;
  margin-bottom: 24px;
  padding: 14px 18px;
  position: relative;

  resize: none;

  font-family: 'Poppins-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: -0.02em;
  color: #ffffff;

  opacity: 0.4;
  border: 1px solid rgba(190, 219, 176, 1);
  box-shadow: 0px 4px 16px rgba(22, 22, 22, 0.08);
  border-radius: 8px;

  &.colorful {
    border: 1px solid rgba(82, 85, 188, 1);
    color: #161616;
    background-color: inherit;
    opacity: 0.4;
    &::placeholder {
      color: rgba(22, 22, 22, 0.5);
    }

    &:focus {
      border: 1px solid #5255bc;
      opacity: initial;
      filter: drop-shadow(0px 4px 16px rgba(22, 22, 22, 0.08));
      outline: none;
    }
  }

  &.dark {
    color: rgba(255, 255, 255, 1);
    background: #1f1f1f;
    border: 1px solid #bedbb0;
    opacity: 0.4;
    box-shadow: 0px 4px 16px rgba(22, 22, 22, 0.08);

    &::placeholder {
      color: rgba(255, 255, 255, 1);
    }

    &:focus {
      border: 1px solid #bedbb0;
      filter: drop-shadow(0px 4px 16px rgba(22, 22, 22, 0.08));
      opacity: initial;
      outline: none;
    }
  }

  &.light {
    color: $black-text-color;
    opacity: 0.4;

    &::placeholder {
      color: rgba(22, 22, 22, 1);
    }

    &:focus {
      filter: drop-shadow(0px 4px 16px rgba(22, 22, 22, 0.08));
      border: 1px solid #bedbb0;
      opacity: initial;
      outline: none;
    }
  }

  @include tablet {
    max-width: 302px;
    width: 100%;
  }

  @include desktop {
    max-width: 302px;
    width: 100%;
  }
}

.textAreaStyle:focus {
  opacity: initial;
}
.errortextAreaCirillica,
.errortextAreaMaxLength {
  position: absolute;
  top: 305px;
  left: 30px;
  font-size: 10px;
  font-family: Poppins-Regular;
  font-weight: 400;
  letter-spacing: -0.24px;

  color: red;
}

.inputModal {
  width: 287px;
  height: 49px;
  padding-left: 18px;

  border: 1px solid #bedbb0;
  border-radius: 8px;
  margin-bottom: 24px;
  position: relative;
  font-family: 'Poppins-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: calc(21 / 14);

  letter-spacing: -0.02em;

  &.colorful {
    border: 1px solid rgba(82, 85, 188, 1);
    color: #161616;
    background-color: inherit;
    opacity: 0.4;
    &::placeholder {
      color: rgba(22, 22, 22, 0.5);
    }

    &:focus {
      border: 1px solid #5255bc;
      outline: none;
      filter: drop-shadow(0px 4px 16px rgba(22, 22, 22, 0.08));
      opacity: initial;
    }
  }

  &.dark {
    color: rgba(255, 255, 255, 1);
    background: #1f1f1f;
    border: 1px solid #bedbb0;
    box-shadow: 0px 4px 16px rgba(22, 22, 22, 0.08);
    opacity: 0.4;

    &::placeholder {
      color: rgba(255, 255, 255, 1);
    }

    &:focus {
      border: 1px solid #bedbb0;
      filter: drop-shadow(0px 4px 16px rgba(22, 22, 22, 0.08));
      outline: none;
      opacity: initial;
    }
  }

  &.light {
    color: $black-text-color;
    opacity: 0.4;

    &::placeholder {
      color: rgba(22, 22, 22, 1);
    }

    &:focus {
      border: 1px solid #bedbb0;
      filter: drop-shadow(0px 4px 16px rgba(22, 22, 22, 0.08));
      background-color: #fcfcfc;
      color: #161616;
      opacity: initial;
      outline: none;
    }
  }

  @include tablet {
    max-width: 302px;
    width: 100%;
  }

  @include desktop {
    max-width: 302px;
    width: 100%;
  }
}

.errorMessage {
  position: absolute;
  font-size: 10px;
  font-family: Poppins-Regular;
  font-weight: 400;
  letter-spacing: -0.24px;

  color: red;
}

.errorTitle {
  @extend .errorMessage;

  top: 52px;
  left: 6px;
}

.errorDescription {
  @extend .errorMessage;

  top: 156px;
  left: 6px;
}

.errorTitleMaxLength,
.errorCirillicaTitle {
  position: absolute;
  top: 128px;
  left: 30px;
  font-size: 10px;
  font-family: Poppins-Regular;
  font-weight: 400;
  letter-spacing: -0.24px;

  color: red;
}

.buttonModal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 287px;
  height: 49px;
  cursor: pointer;

  background: #bedbb0;
  border-radius: 8px;
  border: none;

  font-family: 'Poppins-Regular';
  font-weight: 500;
  font-size: 14px;
  line-height: calc(21 / 14);

  letter-spacing: -0.02em;

  color: #161616;

  padding: 10px;

  &.colorful {
    background: rgba(82, 85, 188, 1);
    color: white;
  }

  &.dark {
    color: rgba(22, 22, 22, 1);
  }

  &.light {
    color: rgba(22, 22, 22, 1);
  }

  @include tablet {
    max-width: 302px;
    width: 100%;
  }

  @include desktop {
    max-width: 302px;
    width: 100%;
  }
}

.iconButtonModal {
  &.colorful {
    stroke: rgba(22, 22, 22, 1);
  }

  &.dark,
  &.light {
    stroke: rgba(255, 255, 255, 1);
  }
}

.iconButtonModalWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  margin-right: 8px;
  background-color: rgba(22, 22, 22, 1);
  border-radius: 6px;

  &.colorful {
    background-color: rgba(255, 255, 255, 1);
  }

  &.dark {
    color: rgba(255, 255, 255, 1);
  }

  &.light {
    color: rgba(22, 22, 22, 1);
  }
}
