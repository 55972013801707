@import '../../assets/styles/vars';

.userInfo {
  display: flex;
  align-items: center;
  column-gap: 8px;

  .name {
    font-family: 'Poppins-SemiBold';
    font-size: 14px;
    line-height: 1.5px;
    letter-spacing: -0.02em;
    color: $black-text-color;

    &.light {
      color: $black-text-color;
    }
    &.dark {
      color: $white-text-color;
    }
    &.colorful {
      color: $black-text-color;
    }
  }

  .avatar {
    border-radius: 8px;
    object-fit: cover;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
}
