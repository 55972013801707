$accent-color: #bedbb0;
$accent-bright-color: #5255bc;
$black-color: #121212;
$dark-color: #1f1f1f;
$white-color: #ffffff;
$secondary-white-color: #fcfcfc;
$off-white-color: #f6f6f7;
$black-text-color: #161616;
$white-text-color: #ffffff;
$light-violet: #ecedfd;
$priority-low-color: #8fa1d0;
$priority-medium-color: #e09cb5;
$priority-high-color: #bedbb0;
$priority-without-color: rgba(22, 22, 22, 0.3);
$priority-without-dark-theme-color: rgba(255, 255, 255, 0.3);

$red-error-color: #ff0000;
$white-background-modal: #fcfcfc;
$icon-main-color: rgba(22, 22, 22, 0.5);
$icon-secondary-color: rgba(255, 255, 255, 0.5);
$start-background-color: linear-gradient(
  180deg,
  rgba(255, 255, 255, 1) 0%,
  rgba(190, 219, 176, 1) 100%
);
$background-color: #e6e6e6;
$middle-color: #ffffffcc;
$middle-color2: #161616cc;
