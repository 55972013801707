@import 'assets/styles/vars';
@import 'assets/styles/mixins';

.item {
  width: 100%;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  scroll-snap-align: start;

  &:hover .iconProject {
    transform: rotate(1turn);
    transition: all 1.5s;
  }
}

.link {
  width: 100%;
  padding: 20px 0 20px 14px;

  @include tablet {
    padding: 20px 0 20px 24px;
  }
}

.projectNameWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.iconProject {
  width: 18px;
  height: 18px;
  stroke: #16161680;

  &.light {
    stroke: #16161680;
  }
  &.dark {
    stroke: #ffffff80;
  }
  &.colorful {
    stroke: #ffffff80;
  }
}

.text {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.02em;
  color: #ffffff80;

  width: 135px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &.light {
    color: #16161680;
  }
  &.dark {
    color: #ffffff80;
  }
  &.colorful {
    color: #ffffff80;
  }
}

.iconList {
  display: none;
  position: absolute;
  // width: 40px;
  gap: 8px;
  top: 23px;
  right: 18px;

  @include tablet {
    right: 24px;
  }

  & li {
    pointer-events: none;
  }

  & li:hover .actionIcon,
  & li:active .actionIcon {
    stroke: $black-color;

    transition: all 0.5s;
  }

  &.light {
    & li:hover .actionIcon,
    & li:active .actionIcon {
      stroke: $black-color;
    }
  }
  &.dark {
    & li:hover .actionIcon,
    & li:active .actionIcon {
      stroke: $white-color;
    }
  }
  &.colorful {
    & li:hover .actionIcon,
    & li:active .actionIcon {
      stroke: $white-color;
    }
  }
}

.actionButton {
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.actionIcon {
  width: 100%;
  height: 100%;
  stroke: #16161680;

  &.light {
    stroke: #16161680;
  }
  &.dark {
    stroke: #ffffff80;
  }
  &.colorful {
    stroke: #ffffff80;
  }
}

.current {
  background-color: $dark-color;
  color: #ffffff80;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 4px;
    height: 100%;
    background: $accent-color;
    border-radius: 4px 0px 0px 4px;
  }

  &.light {
    background-color: $off-white-color;

    &::after {
      background: $accent-color;
    }
  }
  &.dark {
    background-color: $dark-color;

    &::after {
      background: $accent-color;
    }
  }
  &.colorful {
    background-color: rgba(255, 255, 255, 0.5);

    &::after {
      background: $white-color;
    }
  }

  & .iconProject {
    stroke: $black-text-color;

    &.light {
      stroke: $black-text-color;
    }
    &.dark {
      stroke: $white-color;
    }
    &.colorful {
      stroke: $white-color;
    }
  }

  & .text {
    color: $black-text-color;

    &.light {
      color: $black-text-color;
    }
    &.dark {
      color: $white-color;
    }
    &.colorful {
      color: $white-color;
    }
  }

  & .iconList {
    display: flex;
  }

  & .iconList li {
    pointer-events: auto;
  }
}

.removeButtonList {
  display: flex;
  gap: 16px;
}

.removeItem {
  height: 49px;
  width: calc((100% / 2) - 16px);
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover .cancel,
  &:focus .cancel {
    outline: 1px solid $black-text-color;

    &.light {
      outline: 1px solid $black-text-color;
    }
    &.dark {
      outline: 1px solid $white-color;
    }
    &.colorful {
      outline: 1px solid $black-text-color;
    }
  }

  &:hover .remove,
  &:focus .remove {
    text-decoration: underline;
  }
}

.removeButton {
  border-radius: 8px;
  border: none;
  cursor: pointer;

  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.28px;

  &.remove {
    background-color: transparent;
    color: $black-text-color;

    &.light {
      color: $black-text-color;
    }
    &.dark {
      color: $white-color;
    }
    &.colorful {
      color: $black-text-color;
    }
  }

  &.cancel {
    height: 100%;
    width: 100%;
    background-color: $accent-color;
    color: $black-text-color;

    &.light {
      color: $black-text-color;
    }
    &.dark {
      color: $black-text-color;
    }
    &.colorful {
      background-color: $accent-bright-color;
      color: $white-color;
    }
  }
}
