@import '../../assets/styles/vars';

.background {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  background: $start-background-color;
}
