@import '../../assets/styles/vars';
@import '../../assets/styles/mixins';

.title {
  text-align: center;
  font-family: 'Poppins-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.5em;
  letter-spacing: -0.02em;
  margin-bottom: 24px;
  // margin-top: 24px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &.dark {
    color: $white-text-color;
  }

  &.light,
  &.colorful {
    color: $black-text-color;
  }
}

.modal {
  position: relative;

  padding: 24px;

  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);

  border-radius: 8px;

  @include mobile {
    width: 335px;
  }

  @include tablet {
    width: 400px;
  }

  &.dark {
    background-color: $black-text-color;
  }

  &.light,
  &.colorful {
    background-color: $white-background-modal;
  }

  @include tablet {
    min-width: 350px;
    max-width: min-content;
  }
}

.modalHeader {
  display: flex;
  justify-content: space-between;
}

.closeSvg {
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  cursor: pointer;
  // margin: 14px;
  &.dark {
    stroke: $white-text-color;
  }

  &.light,
  &.colorful {
    stroke: $black-text-color;
  }
}

.close {
  width: 18px;
  height: 18px;

  position: absolute;
  top: 14px;
  right: 14px;
}
