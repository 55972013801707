@import '../../assets/styles/vars';

.columnHeaderWrapper {
  width: 335px;
  height: 56px;
  padding: 18px 20px;
  background-color: $white-color;
  border-radius: 8px;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.dark {
    background-color: $black-color;
  }
}

.title {
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  color: $black-text-color;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &.dark {
    color: $white-color;
  }
}

.buttonList {
  display: flex;
}

.item {
  width: 16px;
  height: 16px;

  &:not(:first-child) {
    margin-left: 8px;
  }
}
.columnsList {
  margin-right: 34px;
}
