@import-normalize; /* bring in normalize.css styles */
@import './assets/styles/reset';
@import './assets/styles/vars';
@import './assets/styles/mixins';

@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'),
    url('./assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins-Medium'),
    url('./assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins-SemiBold'),
    url('./assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 500;
}

body {
  background-color: $dark-color;
  margin: 0;
  height: 100vh;
  //width: 100vw;
  // overflow: hidden;
  font-family: 'Poppins-Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  color: $white-text-color;
}

body.no-scroll {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  @include container;
}

// ::-webkit-scrollbar {
//   width: 8px;
//   height: 12px;
//   margin-left: 8px;
//   margin-right: 2px;
// }

// ::-webkit-scrollbar-thumb {
//   // background-color: $dark-color;
//   border-radius: 100vw;
// }

// ::-webkit-scrollbar-track {
//   // background-color: $background-color;
//   border-radius: 100vw;
// }
