@import '../../assets/styles/vars';

.modalWrapper {
  max-width: 350px;
  min-height: 86px;
  padding: 18px;
  border-radius: 8px;
  background-color: $secondary-white-color;
  box-shadow: 0px 0px 15px 0px $priority-high-color;

  &.dark {
    background-color: $dark-color;
  }
}

.item {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.button {
  width: 100%;
  height: 21px;
  color: $black-text-color;
  background-color: transparent;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    transform: scale(1.1);
  }

  &.current {
    color: $priority-high-color;
    pointer-events: none;
  }

  &.dark {
    color: $icon-secondary-color;
    &.current {
      color: $priority-high-color;
    }
  }

  &.colorful.current {
    color: $accent-bright-color;
  }
}

.title {
  text-align: left;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  flex-shrink: 0;
  stroke: $black-text-color;

  &.current {
    stroke: $priority-high-color;
  }

  &.dark {
    stroke: $icon-secondary-color;
    &.current {
      stroke: $priority-high-color;
    }
  }

  &.colorful.current {
    stroke: $accent-bright-color;
  }
}
