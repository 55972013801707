@import '../../assets/styles/vars';
@import '../../assets/styles/mixins';

// r
// .header {
//   width: 100%;
//   background-color: $secondary-white-color;

//   &.light {
//     background-color: $secondary-white-color;
//   }
//   &.dark {
//     background-color: $black-text-color;
//   }
//   &.colorful {
//     background-color: $white-color;
//   }

//   .pageHeader {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     // padding-top: 14px;
//     // padding-bottom: 14px;
//     padding: 14px 20px;

//     .burgerMenu {
//       width: 24px;
//       height: 24px;
//       background-color: inherit;
//       border: none;

//       .burgerIcon {
//         width: 24px;
//         height: 24px;
//         stroke: $black-text-color;

//         &.light {
//           stroke: $black-text-color;
//         }
//         &.dark {
//           stroke: $white-color;
//         }
//         &.colorful {
//           stroke: $black-text-color;
//         }
//       }
//     }
//     .dropDown {
//       position: relative;
//       display: inline-block;
//       margin-left: auto;
//       margin-right: 14px;

//       .dropBtn {
//         display: flex;
//         align-items: center;
//         border: none;
//         cursor: pointer;
//         background-color: inherit;
//         column-gap: 8px;
//         font-size: 14px;
//         letter-spacing: -0.02em;
//         opacity: 0.8;
//         color: $black-text-color;

//         &.light {
//           color: $black-text-color;
//           &.active {
//             font-weight: 500 !important;
//           }
//         }
//         &.dark {
//           color: $white-text-color;
//           &.active {
//             font-weight: 500 !important;
//           }
//         }
//         &.colorful {
//           color: $black-text-color;
//           &.active {
//             font-weight: 500 !important;
//           }
//         }

//         .arrowIcon {
//           width: 16px;
//           height: 16px;
//           stroke: $black-text-color;
//           fill: $secondary-white-color;

//           &.light {
//             stroke: $black-text-color;
//             fill: $secondary-white-color;
//           }
//           &.dark {
//             stroke: $white-text-color;
//             fill: $black-text-color;
//           }
//           &.colorful {
//             stroke: $black-text-color;
//             fill: $white-text-color;
//           }
//         }
//       }

//       .dropDownContent {
//         display: none;
//         position: absolute;
//         padding: 16px;
//         border: 1px solid $accent-color;
//         border-radius: 8px;
//         min-width: 100px;
//         z-index: 1;
//         background-color: $secondary-white-color;

//         &.light {
//           background-color: $secondary-white-color;
//           border-color: $accent-color;
//         }
//         &.dark {
//           background-color: $black-text-color;
//           border-color: $accent-color;
//         }
//         &.colorful {
//           background-color: $white-color;
//           border-color: $light-violet;
//         }

//         .dropDownItem {
//           cursor: pointer;
//           text-decoration: none;
//           display: block;
//           // font-weight: 400;
//           font-size: 14px;
//           line-height: 1.5;
//           margin: 2px;
//           letter-spacing: -0.02em;
//           background-color: inherit;
//           background-color: $secondary-white-color;
//           color: $black-text-color;
//           opacity: 1;

//           &.light {
//             background-color: $secondary-white-color;
//             color: $black-text-color;
//             opacity: 1;
//           }
//           &.dark {
//             background-color: $black-text-color;
//             color: $white-color;
//             opacity: 0.5;
//           }
//           &.colorful {
//             background-color: $white-color;
//             color: $black-text-color;
//             opacity: 1;
//           }
//         }

//         .dropDownItem:hover {
//           color: $accent-color;

//           &.light {
//             color: $accent-color;
//           }
//           &.dark {
//             color: $accent-color;
//           }
//           &.colorful {
//             color: $accent-bright-color;
//           }
//         }
//       }

//       &:hover .dropDownContent {
//         display: block;
//       }
//     }
//   }
// }
// r -End
.header {
  width: 100%;
  background-color: $secondary-white-color;

  &.light {
    background-color: $secondary-white-color;
  }
  &.dark {
    background-color: $black-text-color;
  }
  &.colorful {
    background-color: $white-color;
  }
}
.pageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding-top: 14px;
  // padding-bottom: 14px;
  padding: 14px 20px;
}
.burgerMenu {
  width: 24px;
  height: 24px;
  background-color: inherit;
  border: none;
}
.burgerIcon {
  width: 24px;
  height: 24px;
  stroke: $black-text-color;

  &.light {
    stroke: $black-text-color;
  }
  &.dark {
    stroke: $white-color;
  }
  &.colorful {
    stroke: $black-text-color;
  }
}
.dropDown {
  position: relative;
  display: inline-block;
  margin-left: auto;
  margin-right: 14px;

  // &:hover .dropDownContent {
  //   display: block;
  // }
}

.dropBtn {
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  background-color: inherit;
  column-gap: 8px;
  font-size: 14px;
  letter-spacing: -0.02em;
  opacity: 0.8;
  color: $black-text-color;

  &.light {
    color: $black-text-color;
  }
  &.dark {
    color: $white-text-color;
  }
  &.colorful {
    color: $black-text-color;
  }
}
.arrowIcon {
  width: 16px;
  height: 16px;
  stroke: $black-text-color;
  fill: $secondary-white-color;

  &.light {
    stroke: $black-text-color;
    fill: $secondary-white-color;
  }
  &.dark {
    stroke: $white-text-color;
    fill: $black-text-color;
  }
  &.colorful {
    stroke: $black-text-color;
    fill: $white-text-color;
  }
}

.dropDownContent {
  display: block;
  position: absolute;
  padding: 16px;
  border: 1px solid $accent-color;
  border-radius: 8px;
  min-width: 100px;
  z-index: 1;
  // background-color: $secondary-white-color;

  &.light {
    background-color: $secondary-white-color;
    border-color: $accent-color;
  }
  &.dark {
    background-color: $black-text-color;
    border-color: $accent-color;
  }
  &.colorful {
    background-color: $white-color;
    border-color: $light-violet;
  }
}

.dropDownItem {
  cursor: pointer;
  text-decoration: none;
  display: block;
  // font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  margin: 2px;
  letter-spacing: -0.02em;
  background-color: inherit;
  // background-color: $secondary-white-color;
  color: inherit;
  // color: $black-text-color;
  opacity: 1;

  &.light {
    // background-color: $secondary-white-color;
    background-color: inherit;
    color: $black-text-color;
    opacity: 1;
  }
  &.dark {
    // background-color: $black-text-color;
    background-color: inherit;
    color: $white-color;
    opacity: 0.5;
  }
  &.colorful {
    // background-color: $white-color;
    background-color: inherit;
    color: $black-text-color;
    opacity: 1;
  }
  &.active {
    font-weight: 600;
    text-decoration: underline;
    &.light {
      color: $accent-color;
    }
    &.dark {
      color: $accent-color;
    }
    &.colorful {
      color: $accent-bright-color;
    }
  }
}

.dropDownItem:hover {
  color: $accent-color;

  &.light {
    color: $accent-color;
  }
  &.dark {
    color: $accent-color;
  }
  &.colorful {
    color: $accent-bright-color;
  }
}
// ===

// r
// @include tablet {
//   .header {
//     .pageHeader {
//       padding: 18px 32px;

//       .burgerMenu {
//         width: 32px;
//         height: 32px;

//         .burgerIcon {
//           width: 32px;
//           height: 32px;
//         }
//       }
//     }
//   }
// }
// r -End
@include tablet {
  .pageHeader {
    padding: 18px 32px;
  }
  .burgerMenu {
    width: 32px;
    height: 32px;
  }
  .burgerIcon {
    width: 32px;
    height: 32px;
  }
}
// ===

// r
// @include desktop {
//   .header {
//     .pageHeader {
//       padding: 18px 24px;

//       .burgerMenu {
//         display: none;
//       }
//     }
//   }
// }
// r -End
@include desktop {
  .pageHeader {
    padding: 18px 24px;
  }
  .burgerMenu {
    display: none;
  }
}
// ===
