@import '../../assets/styles/vars';
@import '../../assets/styles/mixins';

.backgroundGroupWrapper {
  display: block;
  margin-bottom: 14px;
}

.label {
  margin-bottom: 14px;

  font-family: 'Poppins-Medium';
  font-weight: 500;
  font-size: 14px;
  line-height: calc(21 / 14);

  letter-spacing: -0.02em;
  &.dark {
    color: $white-text-color;
  }
  &.light,
  &.colorful {
    color: $black-text-color;
  }
}

.radioWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
  @include mobile {
    width: 252px;
  }
}

.radioContainer {
  position: relative;
  display: flex;
  background: inherit;
}

.radioInput {
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
}

.radioLabel,
.radioLabelDefault {
  cursor: pointer;
  // content: '';
  display: block;
  width: 28px;
  height: 28px;
  // width: 100%;
  // height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;

  transform: scale(1);
  transition: transform 150ms linear, outline 150ms linear 75ms;

  border-radius: 6px;

  input:hover + & {
    transform: scale(1.08);
  }

  input:focus + & {
    transform: scale(0.95);

    &.dark {
      outline: 2px solid $accent-color;
    }

    &.light {
      // outline: 2px solid $accent-color;
      outline: 2px solid $accent-bright-color;
    }

    &.colorful {
      outline: 2px solid $accent-bright-color;
    }
  }
}

.radioLabelDefault {
  &.dark {
    background-image: url('../../assets/images/defaultVectorDark.png');
    background-size: contain;
  }

  &.light {
    background-image: url('../../assets/images/defaultVectorLight.png');
    background-size: contain;
  }

  &.colorful {
    background-image: url('../../assets/images/defaultVectorColorFul.png');
    background-size: contain;
  }
}
