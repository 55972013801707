@import 'assets/styles/mixins';

.list {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  height: calc(100vh - 611px);
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  scroll-snap-type: y mandatory;

  @include tablet {
    max-height: calc(100vh - 655px);
  }
  @include desktop {
    max-height: calc(100vh - 654px);
  }

  &::-webkit-scrollbar {
    width: 0;
  }
}
