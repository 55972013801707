@import '../../assets/styles/vars';
@import '../../assets/styles/mixins';

.backGround {
  height: calc(100vh - 60px);
  position: relative;

  &.dark {
    background-color: #1f1f1f;
  }
  &.light {
    background-color: #f6f6f7;
  }
  &.colorful {
    background-color: #ecedfd;
  }

  @include tablet {
    height: calc(100vh - 68px);
  }

  .defaultTitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 400;
    font-size: 14px;
    line-height: 1.29;
    text-align: center;
    letter-spacing: -0.02em;
    max-height: min-content;

    @include mobileSmall {
      max-width: 335px;
    }
    @include mobile {
      width: 335px;
    }
    @include tablet {
      width: 486px;
    }
    &.dark {
      color: #ffffff80;
    }
    &.light,
    &.colorful {
      color: #161616;
    }
  }
}

.defaultLink {
  &.dark,
  &.light {
    color: #bedbb0;
  }
  &.colorful {
    color: #5255bc;
  }
}
