@import '../../assets/styles/vars';
@import '../../assets/styles/mixins';

.section {
  display: flex;
  align-items: center;
  flex-direction: column;

  padding-left: 16px;
  padding-right: 16px;
}

.image {
  display: block;
  width: 124px;
  height: 124px;
  margin-bottom: 14px;

  background-image: url('../../assets/images/welcome-page-mob-1x.png');

  @include tablet {
    margin-bottom: 24px;
    width: 162px;
    height: 162px;

    background-image: url('../../assets/images/welcome-page-1x.png');
  }
}

.wrap {
  display: flex;
  gap: 14px;
  align-items: center;
  margin-bottom: 24px;
}

.icon {
  width: 40px;
  height: 40px;

  @include tablet {
    width: 48px;
    height: 48px;
  }
}

.title {
  color: $black-text-color;
  letter-spacing: -0.04em;
  font-weight: 600;
  font-size: 28px;
  line-height: 1.5;

  @include tablet {
    font-size: 40px;
  }
}

.text {
  margin-bottom: 48px;
  max-width: 335px;

  color: $black-text-color;

  text-align: center;
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: -0.02em;

  @include tablet {
    max-width: 473px;
  }
}

.item {
  max-width: 335px;
  width: 100%;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 49px;
  margin-bottom: 14px;

  border-radius: 8px;

  color: $white-text-color;
  background-color: #161616;

  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.02em;

  @include mobileSmall {
    max-width: 335px;
    width: 100%;
  }

  @include mobile {
    min-width: 335px;
  }

  @include tablet {
    min-width: 344px;
  }
}

.secondLink {
  color: $black-text-color;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
}
