@import '../../assets/styles/vars';

.cardWrapper {
  scroll-snap-align: start;
  position: relative;
  padding: 14px 21px;
  width: 335px;
  margin-right: 8px;
  height: max-content;
  background-color: $white-text-color;
  border-radius: 8px;
  overflow: hidden;
  list-style: none;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &.dark {
    background-color: $black-color;
  }
}

.priorityLine {
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;

  &.dark {
    background-color: $priority-without-dark-theme-color;
  }
}

.title {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  color: $black-text-color;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &.dark {
    color: $white-text-color;
  }
}

.description {
  margin-bottom: 14px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;
  color: $icon-main-color;

  &.dark {
    color: $icon-secondary-color;
  }
}

.showMoreAnchor {
  font-weight: 500;
  color: $priority-high-color;
  cursor: pointer;

  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &.colorful {
    color: $accent-bright-color;
  }

  &:hover {
    font-weight: 700;
    transform: scale(1.04);
    text-decoration: underline;
  }
}

.controlPanel {
  padding-top: 14px;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 1px solid rgba(22, 22, 22, 0.1);

  &.dark {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
}

.statusInfo {
  display: flex;
}

.subtitle {
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 8px;
  line-height: 1.5;
  color: $icon-main-color;

  &.dark {
    color: $icon-secondary-color;
  }
}

.priorityWrapper {
  margin-right: 14px;
}

.priorityStatus {
  display: flex;
}

.priorityCircle {
  width: 12px;
  height: 12px;
  margin-right: 4px;
  border-radius: 50%;
}

.bg_low {
  background-color: $priority-low-color;
  &.dark {
    background-color: $priority-low-color;
  }
}
.bg_medium {
  background-color: $priority-medium-color;
  &.dark {
    background-color: $priority-medium-color;
  }
}
.bg_high {
  background-color: $priority-high-color;
  &.dark {
    background-color: $priority-high-color;
  }
}

.bg_without {
  background-color: $priority-without-color;

  &.dark {
    background-color: $priority-without-dark-theme-color;
  }
}

.text {
  font-weight: 400;
  font-size: 10px;
  line-height: 1.5;
  color: $black-text-color;

  &.dark {
    color: $white-color;
  }
}

.iconsWrapper {
  display: flex;
}

.buttonList {
  display: flex;
  margin-left: 8px;
}

.item {
  width: 16px;
  height: 16px;

  &:not(:first-child) {
    margin-left: 8px;
  }
}

.icon {
  width: 16px;
  height: 16px;
  stroke: $priority-high-color;

  &.colorful {
    stroke: $accent-bright-color;
  }
}
