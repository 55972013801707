.container {
  display: block;
}

.title {
  font-size: 12px;
  font-family: Poppins-Medium;
  font-weight: 400;
  letter-spacing: -0.24px;
  line-height: 18px;

  margin-bottom: 4px;

  &.dark {
    color: rgba(255, 255, 255, 0.5);
  }

  &.colorful,
  &.light {
    color: rgba(22, 22, 22, 0.5);
  }
}

.input_field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // width: 127px;
  height: 21px;
  text-align: left;
  padding-right: 18px;
  margin-bottom: 18px;

  background: inherit;
  // background: red;
  border-radius: 16px;
  border: none;

  font-family: 'Poppins-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: -0.02em;

  cursor: pointer;

  &.colorful {
    color: rgba(82, 85, 188, 1);
  }

  &.dark,
  &.light {
    color: rgba(190, 219, 176, 1);
  }
}
